import React, { useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { Form, Input, Radio, Select } from 'antd';

import { fulfillmentFormSubmit } from 'services/fulfillment';
import { emailRule, phoneNumberRule } from 'utils/forms';
import { ALL_COUNTRIES } from 'constants/countries/constants/countries';
import {
  EXIST_USER_ERROR_CODE,
  ORDERS_NUMBER,
  PRODUCT_CATEGORIES
} from 'constants/fulfillment';

import BRButton from 'components/BRButton/BRButton';
import { notify } from 'components/Notify/Notify';

import './FulfillmentSignup.less';

const FulfillmentSignup = ({ intl, history }) => {
  const formRef = useRef();
  const [isLoading, setIsloading] = useState(false);

  const onSubmit = async (values) => {
    setIsloading(true);
    try {
      await fulfillmentFormSubmit(values);

      notify({
        msg: intl.formatMessage({
          id: 'bosta_fulfillment.form.success'
        }),
        type: 'success'
      });

      formRef.current.resetFields();
    } catch (error) {
      if (error?.errorCode === EXIST_USER_ERROR_CODE) {
        notify({
          msg: intl.formatMessage({
            id: 'bosta_fulfillment.form.failure'
          })
        });
      } else {
        notify({
          msg: error.message
        });
      }
    }
    setIsloading(false);
  };

  return (
    <div id="signup-section" className="br-fulfillment__signup-section">
      <div className="br-fulfillment__signup-text">
        <p className="br-fulfillment__signup-header">
          {intl.formatMessage({
            id: 'bosta_fulfillment.form.title'
          })}
        </p>
        <p className="br-fulfillment__signup-subtitle">
          {intl.formatMessage({
            id: 'bosta_fulfillment.form.subtitle'
          })}
        </p>
      </div>
      <Form
        className="br-fulfillment__signup-form"
        onFinish={onSubmit}
        ref={formRef}
      >
        <Form.Item
          className="body-medium"
          label={intl.formatMessage({
            id: 'bosta_fulfillment.form.business_name'
          })}
          name="name"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={intl.formatMessage({
            id: 'bosta_fulfillment.form.phone_number'
          })}
          name="phone"
          rules={[
            { required: true },
            phoneNumberRule({
              message: intl.formatMessage({ id: 'form.phone_not_valid' }),
              country: ALL_COUNTRIES[0],
              withoutCountryCode: false
            })
          ]}
        >
          <Input type="text" />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({
            id: 'bosta_fulfillment.form.email'
          })}
          name="email"
          rules={[
            { required: true },
            emailRule(
              intl.formatMessage({
                id: 'form.email_not_valid'
              })
            )
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({
            id: 'bosta_fulfillment.form.product_category.title'
          })}
          name="productCategory"
          rules={[{ required: true }]}
        >
          <Select options={PRODUCT_CATEGORIES} />
        </Form.Item>

        <Form.Item
          label={intl.formatMessage({
            id: 'bosta_fulfillment.form.orders_number.title'
          })}
          name="numberOfOrdersPerMonth"
          rules={[{ required: true }]}
        >
          <Select options={ORDERS_NUMBER} />
        </Form.Item>

        <Form.Item
          label={intl.formatMessage({
            id: 'bosta_fulfillment.form.ship_way'
          })}
          name="isWorkingWithBosta"
          rules={[{ required: true }]}
          initialValue={true}
        >
          <Radio.Group value={'1'}>
            <Radio value={true}>
              {intl.formatMessage({
                id: 'common.yes'
              })}
            </Radio>
            <Radio value={false}>
              {intl.formatMessage({
                id: 'common.no'
              })}
            </Radio>
          </Radio.Group>
        </Form.Item>
        <BRButton
          className="br-fulfillment__form-submit-btn"
          label={intl.formatMessage({
            id: 'bosta_fulfillment.form.submit'
          })}
          type="destructive-primary"
          htmlType="submit"
          loading={isLoading}
        />
      </Form>
    </div>
  );
};

export default withRouter(injectIntl(FulfillmentSignup));
