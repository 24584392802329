import React from 'react';

import { PARTNERS } from 'constants/fulfillment';

import './FulfillmentCarousel.less';

const FulfillmentCarousel = () => {
  return (
    <div className="br-fulfillment__carousel_section">
      <div className="br-fulfillment__carousel_logos">
        <div>
          {PARTNERS.map((item, index) => (
            <img key={index} alt="logo" src={item} />
          ))}
          {PARTNERS.map((item, index) => (
            <img key={index} alt="logo" src={item} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FulfillmentCarousel;
