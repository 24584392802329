import { fmt } from 'components/IntlWrapper/IntlWrapper';

import FulfillmentShipping from 'assets/images/fulfillment-shipping.png';
import FulfillmentPackaging from 'assets/images/fulfillment-packaging.png';
import FulfillmentStoring from 'assets/images/fulfillment-storing.png';
import EmmaLogo from 'assets/images/emma-img.png';
import TaagerLogo from 'assets/images/taager-img.png';
import NoonLogo from 'assets/images/noon-img.png';
import AmazonLogo from 'assets/images/amazon-img.png';
import OolAmeme from 'assets/images/ool-ameme.jpg';
import BFSportsWear from 'assets/images/BF-Sportswear.jpg';
import Dejavu from 'assets/images/Dejavu.jpg';
import Essentials from 'assets/images/Essentials.png';
import JockeyJoy from 'assets/images/Jockey-Joy.jpg';
import Kenzz from 'assets/images/Kenzz.png';
import Khotwah from 'assets/images/Khotwah.jpg';
import Pepla from 'assets/images/Pepla.jpg';
import Snuggs from 'assets/images/Snuggs.jpg';
import SourceBeuty from 'assets/images/Source-beauty.png';
import WayUpSport from 'assets/images/Wayup-Sports.jpg';
import TimeIcon from 'assets/icons/fulfillment-time.svg';
import TechIcon from 'assets/icons/fulfillment-technology.svg';
import PeopleIcon from 'assets/icons/fulfillment-people.svg';
import CashIcon from 'assets/icons/fulfillment-cash.svg';
import BoxIcon from 'assets/icons/fulfillment-box.svg';

export const FEATURES_DATA = [
  {
    icon: TimeIcon,
    title: 'bosta_fulfillment.features.shipping.title',
    description: 'bosta_fulfillment.features.shipping.description'
  },
  {
    icon: PeopleIcon,
    title: 'bosta_fulfillment.features.fulfillment.title',
    description: 'bosta_fulfillment.features.fulfillment.description'
  },
  {
    icon: TechIcon,
    title: 'bosta_fulfillment.features.technology.title',
    description: 'bosta_fulfillment.features.technology.description'
  },

  {
    icon: CashIcon,
    title: 'bosta_fulfillment.features.payment.title',
    description: 'bosta_fulfillment.features.payment.description'
  },
  {
    icon: BoxIcon,
    title: 'bosta_fulfillment.features.storage.title',
    description: 'bosta_fulfillment.features.storage.description'
  }
];

export const PROCESS_DATA = [
  {
    label: 'bosta_fulfillment.process.fulfillment',
    image: FulfillmentStoring
  },
  {
    label: 'bosta_fulfillment.process.packaging',
    image: FulfillmentPackaging
  },
  {
    label: 'bosta_fulfillment.process.shipping',
    image: FulfillmentShipping
  }
];

export const getStatsList = (isMediumMobileScreen) => [
  {
    value: fmt({
      id: 'bosta_fulfillment.stats.warehouses.value'
    }),
    title: fmt({
      id: 'bosta_fulfillment.stats.warehouses.title'
    })
  },
  {
    value: fmt({
      id: 'bosta_fulfillment.stats.delivery.value'
    }),
    title: fmt({
      id: 'bosta_fulfillment.stats.delivery.title'
    })
  },
  {
    value: fmt({
      id: 'bosta_fulfillment.stats.partners.value'
    }),
    title: fmt({
      id: 'bosta_fulfillment.stats.partners.title'
    })
  },
  ...(isMediumMobileScreen
    ? [
        {
          value: fmt({
            id: 'bosta_fulfillment.stats.industry.value'
          }),
          title: fmt({
            id: 'bosta_fulfillment.stats.industry.title'
          })
        },
        {
          value: fmt({
            id: 'bosta_fulfillment.stats.parcels.value'
          }),
          title: fmt({
            id: 'bosta_fulfillment.stats.parcels.title'
          })
        }
      ]
    : [
        {
          value: fmt({
            id: 'bosta_fulfillment.stats.parcels.value'
          }),
          title: fmt({
            id: 'bosta_fulfillment.stats.parcels.title'
          })
        },
        {
          value: fmt({
            id: 'bosta_fulfillment.stats.industry.value'
          }),
          title: fmt({
            id: 'bosta_fulfillment.stats.industry.title'
          })
        }
      ])
];

export const FULFILLMENT_VIDEO_URL =
  'https://www.youtube.com/embed/OgP9qx2diMw?si=ryIh8R2i1OY_i9IL';

export const PARTNERS = [
  EmmaLogo,
  AmazonLogo,
  TaagerLogo,
  NoonLogo,
  OolAmeme,
  BFSportsWear,
  Dejavu,
  Essentials,
  JockeyJoy,
  Kenzz,
  Khotwah,
  Pepla,
  Snuggs,
  SourceBeuty,
  WayUpSport,
  EmmaLogo,
  AmazonLogo,
  TaagerLogo,
  NoonLogo,
  OolAmeme,
  BFSportsWear,
  Dejavu,
  Essentials,
  JockeyJoy,
  Kenzz,
  Khotwah,
  Pepla,
  Snuggs,
  SourceBeuty,
  WayUpSport
];

export const PRODUCT_CATEGORIES = [
  {
    value: 'Clothing and Fashion',
    label: fmt({ id: 'bosta_fulfillment.form.product_category.fashion' })
  },
  {
    value: 'Accessories',
    label: fmt({ id: 'bosta_fulfillment.form.product_category.accessories' })
  },
  {
    value: 'Electronics',
    label: fmt({ id: 'bosta_fulfillment.form.product_category.electronics' })
  },
  {
    value: 'Home Tools and Accessories',
    label: fmt({ id: 'bosta_fulfillment.form.product_category.home_tools' })
  },
  {
    value: 'Cosmetics',
    label: fmt({ id: 'bosta_fulfillment.form.product_category.cosmetics' })
  },
  {
    value: 'Toys',
    label: fmt({ id: 'bosta_fulfillment.form.product_category.toys' })
  },
  {
    value: 'Baby Products and Accessories',
    label: fmt({ id: 'bosta_fulfillment.form.product_category.baby' })
  },
  {
    value: 'Books, Arts, and Media',
    label: fmt({ id: 'bosta_fulfillment.form.product_category.arts' })
  },
  {
    value: 'Medical Supplies',
    label: fmt({ id: 'bosta_fulfillment.form.product_category.medical' })
  },
  {
    value: 'Shoes',
    label: fmt({ id: 'bosta_fulfillment.form.product_category.shoes' })
  },
  {
    value: 'Pet Supplies',
    label: fmt({ id: 'bosta_fulfillment.form.product_category.pet_supplies' })
  },
  {
    value: 'Health Supplements',
    label: fmt({
      id: 'bosta_fulfillment.form.product_category.health_supplements'
    })
  },
  {
    value: 'Others',
    label: fmt({ id: 'bosta_fulfillment.form.product_category.others' })
  }
];

export const ORDERS_NUMBER = [
  {
    value: 'Below 100',
    label: fmt({ id: 'bosta_fulfillment.form.orders_number.below_100' })
  },
  {
    value: '100 - 200',
    label: fmt({ id: 'bosta_fulfillment.form.orders_number.100_200' })
  },
  {
    value: '200 - 400',
    label: fmt({ id: 'bosta_fulfillment.form.orders_number.200_400' })
  },
  {
    value: '400 - 1,000',
    label: fmt({
      id: 'bosta_fulfillment.form.orders_number.400_1,000'
    })
  },
  {
    value: '1,000 or more',
    label: fmt({ id: 'bosta_fulfillment.form.orders_number.1,000_or_more' })
  }
];

export const EXIST_USER_ERROR_CODE = 1120001;
